<template>
  <div class="discord">
    <app-header title="Discord Authorization" :hide-back="true"/>
    <div class="layout layout--wrap layout--center">
      <div class="flex" style="max-width: 800px">
        <div class="tile mt">
          <progress-linear v-if="loading" :indeterminate="true"/>
          <div class="bodytext bodytext--larger">
            <h2>Introduction</h2>
            <p>If you have <span class="text--teal">already created an account with email and password</span>, you have to connect your account to discord first.</p>
            <p>Otherwise a <span class="text--teal">new</span> account will be created, if the username is not taken yet.</p>
            <p>If you want to use the Discord login in the future, follow these steps:</p>
            <ul class="futuristic">
              <li>Login with your <span class="text--teal">username</span> and <span class="text--teal">password</span>.</li>
              <li>Click on your <span class="text--teal">username</span> at the top right corner.</li>
              <li>Click on <span class="text--teal">Authorize Discord</span>.</li>
              <li>After you got a positive response, click on your <span class="text--teal">username</span> at the top right corner again.</li>
              <li>On the left site check the <span class="text--teal">Discord Login</span> status.</li>
              <li>If <span class="text--teal">Discord Login</span> shows <span class="ribbon success">connected</span>, you can use the discord login.</li>
              <li>Your username and password will also still work.</li>
            </ul>
            <p>If you need support, don't hesitate to ask for help on our discord: <a href="https://discord.gg/5MMxacV" target="_blank">https://discord.gg/5MMxacV</a> at the channel <span class="text--teal">#en_website_support</span>.</p>
          </div>
          <my-switch
              v-model="introductionsRead"
              :label="'I have read and understood the introductions.'"
          ></my-switch>
          <div class="btn btn--success mt" :disabled="loading || !introductionsRead" @click="redirectToDiscord">Authorize Discord</div>
          <div class="btn mt" :disabled="loading" @click="backToLogin">Back to login</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from '../../components/AppHeader';
import apiDefault from "../../api/default";
import { saveTokens } from "../../api/secured";
import { pageTitleMixin } from "../../mixins/pageTitle";
import { notificationsMixin } from "../../mixins/notifications";

export default {
  name: 'DiscordHandleAuthorization',
  components: {
    AppHeader
  },
  mixins: [pageTitleMixin, notificationsMixin],
  data: () => ({
    loading: false,
    introductionsRead: false,
  }),
  methods: {
    redirectToDiscord() {
      if(!this.loading && this.introductionsRead) {
        this.$router.push({name: 'discord-redirect'});
      }
    },
    backToLogin() {
      if(!this.loading) {
        this.$router.push({name: 'login'});
      }
    }
  },
  created() {
    this.setPageTitle('Discord Authorization');
    if(this.$store.state.user) {
      this.$router.push('/');
      return;
    }

    // ?error=access_denied&error_description=The+resource+owner+or+authorization+server+denied+the+request

    const { query } = this.$route;

    if (query.error) {
      this.showErrorNotifications(query.error);
    }
    if (query.error_description) {
      this.showErrorNotifications(query.error_description);
    }
    if (this.$route.query.code) {
      this.loading = true;
      apiDefault.post('/authentication/token/sign-in-discord', {
        code: query.code,
        redirectUrl: 'https://hq.hyperion-corporation.de/discord-handle'
      }).then((res) => {
        saveTokens(res.data);
        this.$store.commit('setUser');
        this.loading = false;
        this.$router.push('/');
      }).catch((error) => {
        this.loading = false;
        this.errorTitle = 'Fowarding to api failed';
        this.errorMessage = error;
        this.showErrorNotifications(error);
      });
    }
  }
}
</script>
